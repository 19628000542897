import { extendTheme } from "@chakra-ui/react"

// import "@fontsource/Roboto/400.css"
// import "@fontsource/Roboto/700.css"

const theme = {
  // fonts: {
  //   heading: "Roboto",
  //   body: "Roboto",
  // },
  colors: {
    primary: {
      50: "#e4f5fe",
      100: "#c9dde7",
      200: "#aac5d4",
      300: "#8baec1",
      400: "#6b97ae",
      500: "#527e95",
      600: "#3e6274",
      700: "#2a4654",
      800: "#162b35",
      900: "#001018",
    },
  },

  components: {
    Button: {
      // 1. We can update the base styles
      // baseStyle: {
      //   fontWeight: "bold", // Normally, it is "semibold"
      // },
      // 2. We can add a new button size or extend existing
      // sizes: {
      //   xl: {
      //     h: "56px",
      //     fontSize: "lg",
      //     px: "32px",
      //   },
      // },
      // 3. We can add a new visual variant
      variants: {
        // 4. We can override existing variants
        solid: ({ colorMode }) => ({
          bg: colorMode === "dark" ? "red.300" : "primary.500",
          color: colorMode === "dark" ? "red.300" : "white",
          _hover: {
            bg: colorMode === "dark" ? "red.300" : "primary.600",
          },
          _active: {
            bg: colorMode === "dark" ? "red.300" : "primary.700",
          },
        }),
        outline: ({ colorMode }) => ({
          color: colorMode === "dark" ? "red.300" : "primary.500",
          _hover: {
            bg: colorMode === "dark" ? "red.300" : "primary.50",
          },
          _active: {
            bg: colorMode === "dark" ? "red.300" : "primary.200",
            color: colorMode === "dark" ? "red.300" : "white",
          },
        }),
      },
    },
    Drawer: {
      baseStyle: ({ colorMode }) => ({
        dialog: {
          bg: colorMode === "dark" ? "red.300" : "primary.500",
          color: "white",
        },
      }),
    },
    Input: {
      baseStyle: ({ colorMode }) => ({
        field: {
          color: colorMode === "dark" ? "black" : "black",
        },
      }),
    },
    Textarea: {
      baseStyle: ({ colorMode }) => ({
        color: colorMode === "dark" ? "black" : "black",
      }),
    },
  },
}

export default extendTheme(theme)
